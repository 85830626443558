import React from 'react'

function Card({ onClick }) {
    return (
        <div className="bg-white dark:bg-gray-800 m-2 shadow-lg rounded-lg ">
            <img class="rounded-lg flex container" src="https://i.imgur.com/XgnsBmf.jpg" alt=""  />
            <div className='p-2'>
            <h1 className="text-gray-900 dark:text-white">Dark mode is here!</h1>
            <p className="text-gray-600 dark:text-gray-300">Lorem ipsum...lord ameisin</p>
            <button onClick={onClick} className='bg-black text-white px-2 dark:bg-white dark:text-black'>Hola</button>
            </div>
        </div>
    )
}

export default Card
