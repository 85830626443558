import React, { useState } from 'react'
import { Card } from './components'
function App() {
    const [theme, setTheme] = useState(false)
    const handleTheme = () => {
        setTheme(!theme)
    }
    return (
        <div className='body'>
            <div className={theme !== false ? 'container-white flex' : 'container-dark flex'}>
                <Card onClick={handleTheme} />
                <Card onClick={handleTheme} />
                <Card onClick={handleTheme} />
            </div>
        </div>

    )
}

export default App
